import React,{ useRef } from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Avatar,Modal,Form,DatePicker,Select,Space,Typography,Divider,InputNumber,message,Spin } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon, { PrinterOutlined, MinusOutlined, CloseCircleFilled, CheckCircleOutlined, UserOutlined,LoadingOutlined } from '@ant-design/icons';
import { SearchOutlined,CameraOutlined,PlusOutlined } from '@ant-design/icons';
import QrReader from 'react-qr-reader'
import uuid from 'react-uuid'
import PhoneInput from 'react-phone-number-input'
import moment from 'moment';
import PrintTemplate from 'react-print';
import ReactToPrint from 'react-to-print';
import * as serverconfig from '../../views/serverconn'
import CurrencyFormat from 'react-currency-format';
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';

const FormItem=Form.Item;
const { Option } = Select;
const dateFormat = 'DD/MM/YYYY';
const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

var token= ''


class ReportToPrint extends React.Component {

    state = {
      companyprofile:{},
    };
  
    componentDidMount(){
      if(localStorage.getItem("token")){
        token= localStorage.getItem("token")
     }else{
        token= ''
     }
      axios.defaults.headers={
        "Content-Type":"application/json",
        Authorization:`Token ${token}`
      }

      axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
      .then(res => {  
          this.setState({
            companyprofile: res.data
          })
  
      })

  
    }


    render() {
      return (
        <div style={{padding:20}}>
          <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                </Col>
           </Row>
             <br></br> 
             <h2 style={{display: 'flex',justifyContent:'center', alignItems:'center',strong:true}}>{this.props.companyname} SMS Usage Statement from {this.props.dateone}  to {this.props.datetwo}</h2>

             <reactstrp.Table bordered>
             <thead>
                <tr>
                  <th>Date</th>
                  <th>Phone</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
              {this.props.reportitems.map(
                (item)=>(
                  <tr>
                  <td>{item.date}</td>
                  <td>{item.number}</td>
                  <td>{item.message}</td>
                  </tr>
                ))}
                 <tr>                                      
                  <th></th>                                    
                  <th></th>     
                  <th>
                  <h4><strong>Total Number Of Messages: {this.props.totalsms}  </strong>  </h4>
                  <h4><strong>Total Cost:<CurrencyFormat value={this.props.totalsmscost} displayType={'text'} thousandSeparator={true}  /> </strong> </h4>
                  <h4>Country:{this.props.country} ||Currency: {this.props.currency} </h4>
                  </th>                      
                  </tr>  
              </tbody>
            </reactstrp.Table>

        </div>
      );
    }
  }


class CompanySmsUsageStatementReport extends React.Component {

  state = {
    ClientCompanys:[],
    reportitems:[],
    dateone:'',
    datetwo:'',
    company:'',
    companyname:'',
    totalsmscost:0,
    totalsms:0,
    datarequested:true,
    country:'',
    currency:''
   };

   componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }
    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(serverconfig.backendserverurl+`/api/ClientCompany/`)
    .then(res => {
        this.setState({
          ClientCompanys:res.data
        })
        this.setState({datarequested:false})

      })

  }
 
   handledatechange= (date, dateString) =>{
     this.setState({ dateone: dateString[0]});
     this.setState({ datetwo: dateString[1]});
   } 

  handlecompanyChange= (e) =>{
    this.setState({ company: e});
    //get the vendor details
    axios.get(`${serverconfig.backendserverurl}/api/ClientCompany/${e}`)
        .then(res => {  
            this.setState({
                companyname: res.data.company_name
            })
        })

  } 

 
   //search sales method
   searchSales=()=>{
    this.setState({reportitems:[]})
    this.setState({totalsmscost:0})
    this.setState({totalsms:0})
    
     //set loading state
     this.setState({datarequested:true})
    let form_data = new FormData();
    form_data.append('dateone', this.state.dateone);
    form_data.append('datetwo', this.state.datetwo);
    form_data.append('company', this.state.company);

      
    if(this.state.company===''){
      alert("Please select company")
    }else{

        //Now submit sale data to database
        axios.post(serverconfig.backendserverurl+'/customqueries/getcompanysmsstatement', form_data,{
        headers: {
          'content-type': 'multipart/form-data'
        }
        })
        .then(res =>{
           this.setState({datarequested:false})
            this.setState({reportitems:JSON.parse(res.data.report)})
            this.setState({totalsmscost:res.data.totalsmscost})
            this.setState({totalsms:res.data.totalsms})
        } 
        )
        .catch(error => console.log(error))   
    }

    }

   ////////////////////////////////////
  // USER SEARCH SELECT METHODS
  onBlur=()=> {
    console.log('blur');
  }
  
   onFocus=()=>{
    console.log('focus');
  }
  
  onSearch=(val)=> {
    console.log('search:', val);
  }
 

   ////////////////////////////////////////////
   // RENDERING METHOD HERE
   render() {
       
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date',
        key: 'id',
      },      
      {
        title: 'Phone',
        dataIndex: 'number',
        key: 'id',
      },
      {
        title: 'Message',
        dataIndex: 'message',
        key: 'id',
      },

    ];
 
    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )

    }else{
      return(
        <div>
          <div style={{display: "none"}}>
             &nbsp;&nbsp;
            <ReportToPrint
            totalsmscost={this.state.totalsmscost} 
            totalsms={this.state.totalsms} 
            dateone={this.state.dateone}
            datetwo={this.state.datetwo}
            columns={columns}
            reportitems={this.state.reportitems}
            companyname={this.state.companyname}
            currency={this.state.currency}
            country={this.state.country}

            ref={el => (this.componentRef = el)} /> 
          </div>

          <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header={this.state.companyname+" COMPANY SMS STATEMENT REPORT"} key="1">
            <Form  layout="inline" >

            <FormItem label="Company">
                   <Select 
                   placeholder="Company Name"
                   value={this.state.company} 
                   onChange={this.handlecompanyChange}
                   style={{width:300}}                                       
                   showSearch
                   optionFilterProp="children"
                   onFocus={this.onFocus}
                   onBlur={this.onBlur}
                   onSearch={this.onSearch}
                   filterOption={(input, option) =>
                     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                   }   
                    >
                     {this.state.ClientCompanys.map(
                       (itm)=>(
                         <Option value={itm.id}>{itm.company_name}</Option>
                       ))}
                   </Select>
               </FormItem>


                <FormItem label="Date Range">
                    <RangePicker defaultValue={moment()} onChange={this.handledatechange} format={dateFormat} />
                </FormItem>

                <FormItem>
                <Button onClick={this.searchSales} type="primary" htmlType="button">Search</Button>
                </FormItem> 

           </Form>

           <br></br>
            <Table columns={columns} 
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.reportitems}
            scroll={{ x: 1000 }}
           bordered/>  
            <br></br> 
            <Divider style={{borderColor:'red'}} dashed />

            <div style={{overflowX:'auto'}}>
            <Space>

               <h4>TOTAL SMS MESSAGES: {this.state.totalsms}  </h4>
                &nbsp;&nbsp;

                &nbsp;&nbsp;
                <h4>TOTAL COST: <CurrencyFormat value={this.state.totalsmscost} displayType={'text'} thousandSeparator={true}  />   </h4>
                &nbsp;&nbsp;

                <ReactToPrint
                trigger={() => 
                <Button type="primary"  shape="round" icon={<PrinterOutlined />} size={this.state.size}>
                Print
                </Button>
                }
                content={() => this.componentRef}
                />
            </Space>
           </div>

            </Panel>
            </Collapse>

        </div>

    )
    }
 
     
   }
}

export default CompanySmsUsageStatementReport; 
