import React from 'react';
import { Route } from 'react-router-dom';
import AdminIndex from './SiteAdmin/containers/adminindex'
import CompaniesList from './SiteAdmin/containers/Companieslist'
import CompanyDetail from './SiteAdmin/containers/CompanyDetailView'
import CompanyUserList from './SiteAdmin/containers/companyusers'
import SaccoUserAccountDetail from './SiteAdmin/containers/saccouseraccountdetailview'
import SaccoSmsUsagestatementreport from './SiteAdmin/containers/saccosmsusagestatementreport'
import ApplicationDetail from './SiteAdmin/containers/ApplicationDetailView'


const AdminBaseRouter = () =>(
    <div>
        <Route exact path='/admin/' component={AdminIndex} />
        <Route exact path='/' component={AdminIndex}  />
        <Route exact path='/companies/' component={CompaniesList}  />
        <Route exact path='/companies/:companyId' component={CompanyDetail}  />
        <Route exact path='/companyusers/:companyId' component={CompanyUserList}  />
        <Route exact path='/saccouserdetails/:userid' component={SaccoUserAccountDetail}  />
        <Route exact path='/smsusagestatement/' component={SaccoSmsUsagestatementreport}  />
        <Route exact path='/applicationdetails/' component={ApplicationDetail}  />
    </div>

);

export default AdminBaseRouter;


