import React from 'react';
import axios from 'axios'
import { Table, Input, Button,Collapse,Form,InputNumber,Modal,Popover,Spin,Popconfirm,message,Tooltip,Switch } from 'antd';
import Highlighter from 'react-highlight-words';
import Icon from '@ant-design/icons';
import { SearchOutlined,DeleteOutlined,DollarOutlined,RedoOutlined,FundViewOutlined,LoadingOutlined,MehFilled,PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import * as serverconfig from '../../views/serverconn'
import CompanyCreateForm from '../components/CompanyCreateForm'
import {
  Col,
  Row,
} from 'reactstrap';
import * as reactstrp from 'reactstrap';
import ReactToPrint from 'react-to-print';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import PhoneInput from 'react-phone-number-input'

const { Panel } = Collapse;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const FormItem=Form.Item;
const dateFormat = 'DD/MM/YYYY';

function callback(key) {
  console.log(key);
}

var token= ''

class ReceiptToPrint extends React.Component {

  state = {
    companyprofile:{},
    companystatus:false
  };

  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }

    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }

    axios.get(`${serverconfig.backendserverurl}/api/companyprofile/${1}`)
    .then(res => {  
        this.setState({
          companyprofile:res.data
        })

    })

  }


  render() {
    return (
      <div style={{padding:20}}>
          <Row > 
                <Col xs="2" sm="2" lg="2">
                <img height="100" width="120"  alt="Logo" src={this.state.companyprofile.company_logo} />
              
                </Col>
                <Col xs="6" sm="6" lg="6">
                <h3>{this.state.companyprofile.company_name}</h3>
                <h6>Tel: {this.state.companyprofile.RegisteredPhoneNumber1}
                </h6>               
                <h5>Email: {this.state.companyprofile.company_email}</h5>
                <h5>{this.state.companyprofile.box_number}</h5>
                </Col>
           </Row>
          <br></br>
          <h3 style={{display: 'flex',justifyContent:'center', alignItems:'center'}}><strong>{"AVAILABLE COMPANIES"}</strong></h3>

          <Table columns={this.props.columns} 
          pagination={{hideOnSinglePage:true,responsive:true}} 
          dataSource={this.props.companymanagers}  
          bordered/>
         
      </div>
    );
  }
}



class CompaniesList extends React.Component {
  state = {
    searchText: '',
    searchedColumn: '',
    clientcompanies: [],
    companymanagers:[],
    datarequested:true,
    walletmodalvisible:false,
    selectedsaccooject:{},
    date:moment().format(dateFormat).toString(),
    saccodebitamount:0,
    mobilephone:''

  };

      //////////////////////////////////////////////////
  //handle delete here
  handleDelete = id => {
    //Now submit sale data to database
    axios.delete(serverconfig.backendserverurl+`/api/ClientCompany/${id}`)
    .then(res =>{
      window.location.reload(false)
      message.info('Successfully deleted company')
    }
    )
    .catch(error => console.log(error)) 
  };

  handlecompanydatareset=id=>{
    this.setState({datarequested:true})

    let form_data2 = new FormData();
    form_data2.append('companyid', id);

    //Now submit sale data to database
    axios.post(serverconfig.backendserverurl+'/customqueries/resetcompanydata', form_data2,{
      headers: {
        'content-type': 'multipart/form-data'
      }
        })
        .then(res =>{
          this.setState({datarequested:false})
          if(res.data.message==="success"){
            message.info("Successful data reset for sacco")
            window.location.reload(false)
          }

        })
        .catch(error => console.log(error)) 

  }


  componentDidMount(){
    if(localStorage.getItem("token")){
      token= localStorage.getItem("token")
   }else{
      token= ''
   }


    axios.defaults.headers={
      "Content-Type":"application/json",
      Authorization:`Token ${token}`
    }


    axios.get(serverconfig.backendserverurl+'/api/ClientCompany/')
    .then(res => {
        this.setState({
          clientcompanies:res.data
        })
    })

    let form_data2 = new FormData();

    //Now submit sale data to database
    axios.get(serverconfig.backendserverurl+'/customqueries/getcompaniesandmanagers', form_data2,{
      headers: {
        'content-type': 'multipart/form-data'
      }
        })
        .then(res =>{
          this.setState({companymanagers:JSON.parse(res.data.allcompanys)})
          this.setState({datarequested:false})

        })
        .catch(error => console.log(error)) 

}

  /////////////////////////////////////
  // change company status
  changecompanystatus = (val,record) => {
    console.log(val)
    this.setState({companystatus:val})
    this.setState({datarequested:true})

    //now update the company openness
    let form_data = new FormData();
      form_data.append('activated', val);
      form_data.append('usesms', record.usesms);
      form_data.append('userbalance_enabled', record.userbalance_enabled);
      form_data.append('directStockeditingenabled', record.directStockeditingenabled);

      axios.put(`${serverconfig.backendserverurl}/api/ClientCompany/${record.id}/`,form_data, {
          headers: {
          'content-type': 'multipart/form-data'
          }
      })
      .then(res => {
        this.setState({datarequested:false})
        window.location.reload(false)
      })
      .catch(error => console.log(error))

  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };


  //open the wallet modal
  closewalletmodal=()=>{
    this.setState({walletmodalvisible:false})
  }
  
  //open the wallet modal
  openwalletmodal=(record)=>{
    this.setState({mobilephone:''})
    this.setState({saccodebitamount:0})

    console.log(record)
    this.setState({walletmodalvisible:true})
    this.setState({selectedsaccooject:record})
  }

  handlemobilephoneChange= (e) => this.setState({ mobilephone: e});
  handlesaccodebitamountChange=(e)=>this.setState({saccodebitamount:e})

  
  render() {
    const columns = [
      
      {
        title: 'Name',
        dataIndex: 'company_name',
        key: 'id',
        ...this.getColumnSearchProps('company_name'),
      },
      {
        title: 'Branch',
        dataIndex: 'branch_name',
        key: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'company_email',
        key: 'id',
      },
      {
        title: 'Licence',
        dataIndex: 'licencedays',
        key: 'id',
      },      
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text,record) =>
        <p>
        <Popover content={<p>Click here to edit,view details</p>} title="Edit,View">
         <Link to={`/companies/${text}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>

        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>View Company Users</p>} title="View">
         <Link to={`/companyusers/${record.id}`}><FundViewOutlined style={{color:'blue'}}/></Link>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>View Company status</p>} title="Sacco Status">
        <Switch
              checked={record.activated}
              onChange={val=>{this.changecompanystatus(val,record)}}
              checkedChildren="Active"
              unCheckedChildren="     InActive"
            />
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to reset sacco data</p>} title="Reset">
        <Popconfirm title="Sure to reset data?" onConfirm={() => this.handlecompanydatareset(text)}>
          <RedoOutlined style={{color:'green'}}/>
         </Popconfirm>
        </Popover>
        &nbsp;&nbsp;&nbsp;
        <Popover content={<p>Click here to delete sacco</p>} title="Delete">
        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(text)}>
          <DeleteOutlined style={{color:'red'}}/>
         </Popconfirm>
        </Popover>
        </p>,
      },

    ];

    const printcolumns = [
      {
        title: 'Name',
        dataIndex: 'company_name',
        key: 'id',
        ...this.getColumnSearchProps('company_name'),
      },
      {
        title: 'Branch',
        dataIndex: 'branch_name',
        key: 'id',
      },
      {
        title: 'Email',
        dataIndex: 'company_email',
        key: 'id',
      },
      {
        title: 'Phone',
        dataIndex: 'RegisteredPhoneNumber1',
        key: 'id',
      },
      {
        title: 'Licence',
        dataIndex: 'licencedays',
        key: 'id',
      }, 

      {
        title: 'Manager',
        dataIndex: 'manager',
        key: 'id',
      }, 
      {
        title: 'Total Members',
        dataIndex: 'totalmembers',
        key: 'id',
      }, 
      {
        title: 'Total Users',
        dataIndex: 'totalusers',
        key: 'id',
      }, 
    ];



    if(this.state.datarequested===true){
      return(
        <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
        <Spin indicator={antIcon} />
        </div>
      )
    }else{

      return(
        <div>
            <Collapse defaultActiveKey={['1']} onChange={this.callback}>
            <Panel header="CLIENT COMPANIES" key="1">
            <Table 
            columns={columns}
            pagination={{showQuickJumper:true,showSizeChanger:true }}
            dataSource={this.state.clientcompanies}
            scroll={{ x: 1000 }}
            bordered/>
            </Panel>
            </Collapse>
            <br />

 
            <Row > 
              <Col xs="12" sm="6" lg="6">
                <Collapse defaultActiveKey={['0']} onChange={callback}>
                <Panel header="Collapse Panel to open company creation Form" key="1">
                  <h2>Create Company here</h2>
                  <CompanyCreateForm />
                </Panel>
                </Collapse>

              </Col>
   
             
              <Col xs="12" sm="6" lg="6">
                <a>
                <ReactToPrint
                  trigger={() =>                     
                    <div>
                      <Tooltip title="Click to print all companies" placement="bottom">
                      <p ><PrinterOutlined style={{ fontSize: '40px', color: '#08c' }}/></p>
                      <h6 >Print</h6>
                      </Tooltip>
                    </div>                          
                    }
                    content={() => this.componentRef}
                  /> 
                </a>

              </Col>
                       
          </Row>
          
            <div style={{display: "none"}}>
            &nbsp;&nbsp;
            <ReceiptToPrint
            columns={printcolumns}
            companymanagers={this.state.companymanagers}
            ref={el => (this.componentRef = el)} /> 
             </div>


        </div>
    )

    }

  
  }
}

export default CompaniesList; 

