import React from 'react';
import axios from 'axios';
import { Card, Button,Form,Avatar,Spin,Image,Modal,Input,message,Select,Checkbox,Switch } from 'antd';
import CompanyProfileForm from '../components/CompanyProfileForm'
import { UserOutlined } from '@ant-design/icons';
import { PlusOutlined,LoadingOutlined } from '@ant-design/icons';
import ImgsViewer from 'react-images-viewer'
import PhoneInput from 'react-phone-number-input'
import * as serverconfig from '../../views/serverconn'
import moment from 'moment';

import {
    Col,
    Row,
  } from 'reactstrap';

const FormItem=Form.Item;
const { Meta } = Card;
const { Option } = Select;
const { TextArea } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const dateFormat = 'HHmmssDDMMYYYY';

var token= ''

class CompanyDetail extends React.Component{

    state={
        company_name:'',
        branch_name:'',
        company_logo:null,
        company_email:'',
        RegisteredPhoneNumber1:'',
        RegisteredPhoneNumber2:'',
        RegisteredPhoneNumber3:'',
        box_number:'',
        safe_balance:0,
        cash_at_bank:0,
        institutional_capital:0,
        external_loan:0,
        surplus:0,
        retained_earnings:0,
        currentAsset_balance:0,
        vatpercentage:0,
        companydealership:'',
        tinnumber:'',
        website:'',
        document_signature:null,
        usesms:false,
        smsbalance:0,
        userbalance_enabled:false,
        directStockeditingenabled:false,
        licencedays:0,
        accountslimit:0,
        activated:false,
        profile:{}
    }


    componentDidMount(){
        if(localStorage.getItem("token")){
            token= localStorage.getItem("token")
         }else{
            token= ''
         }

        axios.defaults.headers={
            "Content-Type":"application/json",
            Authorization:`Token ${token}`
          }

        //component mount taks
        this.componentmounttasks();

    }

    //component mount tasks
    componentmounttasks=()=>{

        const companyId=this.props.match.params.companyId;
        axios.get(`${serverconfig.backendserverurl}/api/ClientCompany/${companyId}`)
        .then(res => {  
            this.setState({
                profile: res.data
            })

            this.setState({company_name:res.data.company_name})
            this.setState({branch_name:res.data.branch_name})
            this.setState({company_logo:res.data.company_logo})
            this.setState({company_email:res.data.company_email})
            this.setState({RegisteredPhoneNumber1:res.data.RegisteredPhoneNumber1})
            this.setState({RegisteredPhoneNumber2:res.data.RegisteredPhoneNumber2})
            this.setState({RegisteredPhoneNumber3:res.data.RegisteredPhoneNumber3})
            this.setState({box_number:res.data.box_number})
            this.setState({safe_balance:res.data.safe_balance})
            this.setState({cash_at_bank:res.data.cash_at_bank})
            this.setState({institutional_capital:res.data.institutional_capital})
            this.setState({external_loan:res.data.external_loan})
            this.setState({surplus:res.data.surplus})
            this.setState({retained_earnings:res.data.retained_earnings})
            this.setState({currentAsset_balance:res.data.currentAsset_balance})
            this.setState({vatpercentage:res.data.vatpercentage})

            this.setState({companydealership:res.data.companydealership})
            this.setState({tinnumber:res.data.tinnumber})
            this.setState({website:res.data.website})

            this.setState({document_signature:res.data.document_signature})
            this.setState({usesms:res.data.usesms})
            this.setState({smsbalance:res.data.smsbalance})

            this.setState({userbalance_enabled:res.data.userbalance_enabled})
            this.setState({directStockeditingenabled:res.data.directStockeditingenabled})
            this.setState({licencedays:res.data.licencedays})
            this.setState({showsubaccounttransfer:res.data.showsubaccounttransfer})
            this.setState({accountslimit:res.data.accountslimit})
            this.setState({activated:res.data.activated})

            this.setState({datarequested:false});

        })

    }



    //submit button pressed
    handleFormSubmit=() =>{
        const companyId=this.props.match.params.companyId;

        this.setState({datarequested:true})

        let form_data = new FormData();
        form_data.append('company_name', this.state.company_name);
        form_data.append('branch_name', this.state.branch_name);
        form_data.append('company_email', this.state.company_email);
        form_data.append('RegisteredPhoneNumber1', this.state.RegisteredPhoneNumber1);
        form_data.append('box_number', this.state.boxnumber);
    
        var complog=this.state.companylogo
        this.state.companylogo==null || complog.constructor === String?
        console.log("No profile file")
        :
        form_data.append('company_logo', this.state.companylogo,this.state.companylogo?this.state.companylogo.name:"");
        
        var siglog=this.state.document_signature
        this.state.document_signature==null || siglog.constructor === String?
        console.log("No signature file")
        :
        form_data.append('document_signature', this.state.document_signature,this.state.document_signature?this.state.document_signature.name:"");


        form_data.append('website',this.state.website);
        form_data.append('RegisteredPhoneNumber2', this.state.RegisteredPhoneNumber2);
        form_data.append('RegisteredPhoneNumber3', this.state.RegisteredPhoneNumber3);
    
        form_data.append('safe_balance', this.state.safe_balance);
        form_data.append('cash_at_bank', this.state.cash_at_bank);
        
        form_data.append('institutional_capital', this.state.institutional_capital);
        form_data.append('external_loan', this.state.external_loan);
        form_data.append('surplus', this.state.surplus);
        form_data.append('retained_earnings', this.state.retained_earnings);
    
        form_data.append('currentAsset_balance', this.state.currentAsset_balance);
        form_data.append('vatpercentage', this.state.vatpercentage);
        form_data.append('companydealership', this.state.companydealership);
        form_data.append('tinnumber', this.state.tinnumber);
    
        form_data.append('activated', this.state.activated);
        form_data.append('usesms', this.state.usesms);
        form_data.append('smsbalance', this.state.smsbalance);
        form_data.append('userbalance_enabled', this.state.userbalance_enabled);
        form_data.append('directStockeditingenabled', this.state.directStockeditingenabled);
        form_data.append('licencedays', this.state.licencedays);
        form_data.append('accountslimit',this.state.accountslimit);

          axios.put(serverconfig.backendserverurl+`/api/ClientCompany/${companyId}/`, form_data, {
            headers: {
              'content-type': 'multipart/form-data'
            }
          })
        .then(res =>{
            this.setState({datarequested:false})
            message.info('Successfully updated company')
            this.componentmounttasks();
        }
        )
        .catch(error => console.log(error))

    }


    render(){
        if(this.state.datarequested===true){
            return(
              <div style={{display: 'flex',  justifyContent:'center', alignItems:'center' }}>
              <Spin indicator={antIcon} />
              </div>
            )
          }else{
            return(
                <div>
                    <Row>
    


                    <Col xs="12" sm="6" lg="6">
            <Card 
            style={{padding:10}}
            hoverable>
    
            <h4>Company Profile Update</h4>
            <Form>
             <FormItem label="Company Name"
              >
              <Input name="sacconame"  placeholder="Put a name here." value={this.state.company_name} onChange={(val)=>{this.setState({company_name:val.target.value})}} />
            </FormItem>
  
  
            <FormItem label="Branch Name"

              >
              <Input  placeholder="Put a name here." value={this.state.branch_name} onChange={(val)=>{this.setState({branch_name:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Company Logo">
              <Input name="companylogo" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={(val)=>{this.setState({branch_name:val.target.files[0]})}}/>
            </FormItem>  
            
            <FormItem label="Document Signature">
              <Input name="signature" type="file" accept="image/png, image/jpeg" placeholder="Company logo"  onChange={(val)=>{this.setState({document_signature:val.target.files[0]})}}/>
            </FormItem>             


            <FormItem label="Company Email"
            >
              <Input name="companyemail" type="email"  placeholder="company@email.com" value={this.state.company_email} onChange={(val)=>{this.setState({company_email:val.target.value})}} />
            </FormItem> 
  
            <FormItem label="Phone Number">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber1} onChange={(val)=>{this.setState({RegisteredPhoneNumber1:val})}}/>
             </FormItem>
             <FormItem label="Phone Number Two">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber2} onChange={(val)=>{this.setState({RegisteredPhoneNumber2:val})}}/>
             </FormItem> 
  
             <FormItem label="Phone Number Three">
            <PhoneInput
                  countrySelectProps={{ unicodeFlags: true }}
                  defaultCountry="UG"
                  placeholder="Enter phone number"
                  value={this.state.RegisteredPhoneNumber3} onChange={(val)=>{this.setState({RegisteredPhoneNumber3:val})}}/>
             </FormItem>  
             <FormItem label="Website">
              <Input  name="website"  placeholder="Webiste here" value={this.state.website} onChange={(val)=>{this.setState({website:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Box number">
              <Input name="boxnumber"  placeholder="Box number here" value={this.state.box_number} onChange={(val)=>{this.setState({box_number:val.target.value})}} />
            </FormItem>

            <FormItem label="Vat Percentage">
              <Input name="vatpercentage"  placeholder="Vat Percentage" value={this.state.vatpercentage} onChange={(val)=>{this.setState({vatpercentage:val.target.value})}} />
            </FormItem>

            <FormItem label="Tin Number">
              <Input name="tinnumber"  placeholder="Tin Number" value={this.state.tinnumber} onChange={(val)=>{this.setState({tinnumber:val.target.value})}} />
            </FormItem>

            <FormItem label="Company Dealership">

              <TextArea
                name="companydealership" 
                placeholder="Company Dealership"
                value={this.state.companydealership} 
                onChange={(val)=>{this.setState({companydealership:val.target.value})}}
                />
           
            </FormItem>


            <FormItem label="Licence"

            >
              <Input name="licence"  placeholder="Licence days" value={this.state.licencedays} onChange={(val)=>{this.setState({licencedays:val.target.value})}} />
            </FormItem>
  
            <FormItem label="Accounts Limit"

            >
              <Input name="accountslimit"  placeholder="Accounts Limit" value={this.state.accountslimit} onChange={(val)=>{this.setState({accountslimit:val.target.value})}} />
            </FormItem>

            <FormItem label="Safe Balance">
              <Input name="safe_balance"  placeholder="Safe Balance" value={this.state.safe_balance} onChange={(val)=>{this.setState({safe_balance:val.target.value})}} />
            </FormItem>

            <FormItem label="Cash At Bank">
              <Input name="cash_at_bank"  placeholder="Cash At Bank" value={this.state.cash_at_bank} onChange={(val)=>{this.setState({cash_at_bank:val.target.value})}} />
            </FormItem>

            <FormItem label="Institutional Capital">
              <Input name="institutional_capital"  placeholder="Institutional Capital" value={this.state.institutional_capital} onChange={(val)=>{this.setState({institutional_capital:val.target.value})}} />
            </FormItem>

            <FormItem label="External Loan">
              <Input name="external_loan"  placeholder="External Loan" value={this.state.external_loan} onChange={(val)=>{this.setState({external_loan:val.target.value})}} />
            </FormItem>

            <FormItem label="Surplus">
              <Input name="surplus"  placeholder="Surplus" value={this.state.surplus} onChange={(val)=>{this.setState({surplus:val.target.value})}} />
            </FormItem>

            <FormItem label="Retained Earnings">
              <Input name="retained_earnings"  placeholder="Retained Earnings" value={this.state.retained_earnings} onChange={(val)=>{this.setState({retained_earnings:val.target.value})}} />
            </FormItem>

            <FormItem label="Current Asset Balance">
              <Input name="currentAsset_balance"  placeholder="Current Asset Balance" value={this.state.currentAsset_balance} onChange={(val)=>{this.setState({currentAsset_balance:val.target.value})}} />
            </FormItem>



            <FormItem label="Uses SMS" >
                <Switch
                    checked={this.state.usesms}
                    onChange={val=>{this.setState({usesms:!this.state.usesms})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    />
                
            </FormItem>

            <FormItem label="SMS Balance">
              <Input name="smsbalance"  placeholder="Current Asset Balance" value={this.state.smsbalance} onChange={(val)=>{this.setState({smsbalance:val.target.value})}} />
            </FormItem>


            <FormItem name="userbalance_enabled" label="User Balance Enabled?" >
                <Switch
                    checked={this.state.userbalance_enabled}
                    onChange={val=>{this.setState({userbalance_enabled:!this.state.userbalance_enabled})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    /> 
            </FormItem>

            <FormItem name="directStockeditingenabled" label="Direct Stock Editing Enabled?" >
                <Switch
                    checked={this.state.directStockeditingenabled}
                    onChange={val=>{this.setState({directStockeditingenabled:!this.state.directStockeditingenabled})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    /> 
            </FormItem>

            <FormItem name="activated" label="Activated?" >
                <Switch
                    checked={this.state.activated}
                    onChange={val=>{this.setState({activated:!this.state.activated})}}
                    checkedChildren="ON"
                    unCheckedChildren="OFF"
                    /> 
            </FormItem>
        
            <FormItem>
            <Button type="primary" htmlType="button" onClick={(event) => this.handleFormSubmit()}>Update</Button>
            </FormItem>
            </Form>
            </Card>
                

            </Col>

            <Col xs="12" sm="6" lg="6">
                <Card 
                    style={{padding:10,alignContent:'center',alignItems:'center'}}
                    hoverable
                >
                    <h4>Company Logo</h4>
                    <Image
                        width={100}
                        //height={'50%'}
                        src={this.state.profile.company_logo}    
                        /> 
                    <h4>Signature</h4>
                    <Image
                        width={100}
                        //height={'50%'}
                        src={this.state.profile.document_signature}    
                        />
                </Card>

            </Col>
                   
        </Row>
    
        </div>
            )
          }
    }
}

export default CompanyDetail; 